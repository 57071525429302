
































































































































































































import { DisplayBoolean } from "@/components/DisplayBoolean";
import { DisplayTotal } from "@/components/GoodsReceiptPrice";
import { DATE_TIME_HOURS_DEFAULT_FORMAT } from "@/models/constants/date.constant";
import { ReceivingItemAssetRequestDto } from "@/models/interface/receive-item";
import { ReceiveItemRow, State } from "@/store/goodsReceiptPrice.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";

@Component({
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  components: {
    DisplayBoolean,
    DisplayTotal,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.goodsReceiptPriceStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  methods: {
    ...mapActions({
      resetStore: "goodsReceiptPriceStore/resetStore",
    }),
  },
})
export default class DetailPage extends Vue {
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  store!: State;
  storeBaseDecimalPlace!: number;
  resetStore!: () => void;

  columns = [
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "merk",
      width: "100px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_receipt_qty"),
      dataIndex: "qty",
      width: "100px",
      scopedSlots: { customRender: "qty" }, // input manual
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "productUom",
      width: "100px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_unit_price"),
      dataIndex: "price",
      scopedSlots: { customRender: "currency" }, // input manual
    },
    {
      title: this.$t("lbl_tax_base_dpp"),
      dataIndex: "taxBase",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_discount_amount"),
      dataIndex: "discountValue",
      scopedSlots: { customRender: "currency" }, // input manual
    },
    {
      title: this.$t("lbl_tax_value"),
      dataIndex: "tax",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_tax_code"),
      dataIndex: "taxCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_total_idr"),
      dataIndex: "totalPrice",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_receipt_location"),
      dataIndex: "locationReceived",
      scopedSlots: { customRender: "nullable" }, // input manual
    },
    {
      title: this.$t("lbl_track_as_asset"),
      dataIndex: "trackAsAsset", // input manual
      width: "150px",
      scopedSlots: { customRender: "boolean" },
    },
    {
      // ini buat nampilin additional data dalam bentuk modals
      // serial number, type, specification, capacity
      title: this.$t("lbl_notes"),
      scopedSlots: { customRender: "notes" },
    },
  ];

  columnsAsset = [
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_capacity"),
      dataIndex: "capacity",
      scopedSlots: { customRender: "nullable" },
    },
  ];

  loading = {
    print: false,
  };

  modal = {
    visible: false,
    data: [] as Array<ReceivingItemAssetRequestDto>,
    toggle(): void {
      this.visible = !this.visible;
    },
  };

  showModal(row: ReceiveItemRow): void {
    this.modal.data = row.itemAssets;
    this.modal.toggle();
  }

  handleBack(): void {
    this.$router.push({
      name: "purchasing.goods-receipt-price",
    });
  }
}
